const clamp = (val, min, max) => Math.max(min, Math.min(max, val));

const mapRange = (
  input,
  inLow,
  inHigh,
  outLow,
  outHigh,
  clampValue = false
) => {
  const value =
    ((input - inLow) / (inHigh - inLow)) * (outHigh - outLow) + outLow;

  return clampValue ? clamp(value, outLow, outHigh) : value;
};

const slugify = (string) => {
  return string
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/å/g, 'a')
    .replace(/ä/g, 'a')
    .replace(/ö/g, 'o')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};

const formatNumberReadable = (number, opts = {}) => {
  let { thousandsSeparator = '&ThinSpace;' } = opts || {};
  return (number + '').replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
};

const endPadString = (s, char, padAmount) => {
  let padStr = char.repeat(padAmount);
  return `${s}${padStr}`;
};

const easeOutCubic = (x) => {
  return 1 - Math.pow(1 - x, 3);
};

const calculateVisibilityPercentage = (element) => {
  const rect = element.getBoundingClientRect();
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;

  // If the element is partially visible along the x-axis, calculate the visibility percentage
  const visibleWidth =
    Math.min(rect.right, windowWidth) - Math.max(rect.left, 0);
  const elementWidth = rect.width;
  const visibilityPercentage = (visibleWidth / elementWidth) * 100;
  return visibilityPercentage;
};

export {
  clamp,
  mapRange,
  slugify,
  formatNumberReadable,
  endPadString,
  easeOutCubic,
  calculateVisibilityPercentage,
};

import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

function useWindowWidthTitles(isDesktop, delay = 120) {
  const [width, setWidth] = useState(isDesktop ? 1024 : 600); // Default to 1024 for desktop and 600 for mobile

  useEffect(() => {
    if (window?.innerWidth) {
      setWidth(window?.innerWidth);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window?.innerWidth);
    const debouncedHandleResize =
      delay > 0 ? debounce(handleResize, delay) : handleResize;

    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [delay]);

  return width;
}

export default useWindowWidthTitles;

import { useEffect } from 'react';

const useDelayedEffect = (delay = 300, fn, deps = []) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      fn();
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, deps);
};

export default useDelayedEffect;

import React, { useState, useEffect, useCallback, useContext } from 'react';
import Image from 'next/image';
import Div100vh from 'react-div-100vh';

import {
  motion,
  useScroll,
  useTransform,
  AnimatePresence,
} from 'framer-motion';

import { useTranslation } from 'next-i18next';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import ButtonSecondary from '../ButtonSecondary/ButtonSecondary';
import {
  StaggeredDelayedAnimation,
  AnimatedWords,
} from '../StaggeredDelayedAnimation/StaggeredDelayedAnimation';

import useMediaQuery from '../../hooks/useMediaQuery';
import usePreviousValue from '../../hooks/usePreviousValue';
import useDelayedEffect from '../../hooks/useDelayedEffect';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { ZoomLevelContext } from '../../containers/BasePage';

import {
  trackHeroCarouselSlideImpression,
  trackHeroCarouselSlideSelect,
  trackHeroCarouselCtaClick,
} from '../../utils/dataLayer';

import { mapRange, easeOutCubic } from '../../utils/helpers';

import s from './VideoTextCarouselHero.module.scss';

export const SCENE_TYPE_INTRO = 'scene-intro';
export const SCENE_TYPE_REGULAR = 'scene-regular';
export const SCENE_TYPE_CONTACT = 'scene-contact';

const CONTROLS_OPACITY_DELAY = 3 * 1000;

const videoSrcsDesktop = [
  {
    src: '/video/why-bg--desktop--vp9.webm',
    type: 'video/webm; codecs="vp9"',
  },
  {
    src: '/video/why-bg--desktop--vp8.webm',
    type: 'video/webm; codecs="vp8"',
  },
  {
    src: '/video/why-bg--desktop--hevc.mp4',
    type: 'video/mp4',
  },
];

const videoSrcsResp = [
  {
    src: '/video/why-bg--responsive--vp9.webm',
    type: 'video/webm; codecs="vp9"',
  },
  {
    src: '/video/why-bg--responsive--vp8.webm',
    type: 'video/webm; codecs="vp8"',
  },
  {
    src: '/video/why-bg--responsive--hevc.mp4',
    type: 'video/mp4',
  },
];

const IntroScene = (props) => {
  const { regularTitle, boldTitle } = props;

  const { t } = useTranslation();

  return (
    <motion.div
      className={s.IntroSceneContainer}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          duration: 2,
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          duration: 0.2,
        },
      }}>
      <h1 className={s.IntroHeading}>
        <StaggeredDelayedAnimation stagger={0.225}>
          <span className={s.IntroHeadingRegular}>
            <AnimatedWords text={regularTitle} />
          </span>
          {boldTitle && (
            <span className={s.IntroHeadingBold}>
              <AnimatedWords text={boldTitle} />
            </span>
          )}
        </StaggeredDelayedAnimation>
      </h1>
    </motion.div>
  );
};

const BaseScene = (props) => {
  const { regularTitle, boldTitle, preamble } = props;

  return (
    <motion.div
      className={s.BaseSceneContainer}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.2,
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          duration: 0.2,
        },
      }}>
      <div>
        <StaggeredDelayedAnimation stagger={0.225}>
          <h2 className={`${s.BaseHeading} grid`}>
            <span className={s.BaseHeadingRegular}>
              <AnimatedWords text={regularTitle} />
            </span>
            <span className={s.BaseHeadingBold}>
              <AnimatedWords text={boldTitle} initialDelay="1" />
            </span>
          </h2>
        </StaggeredDelayedAnimation>

        {preamble && (
          <div className={`${s.BasePreambleContainer} grid`}>
            <div className={s.BasePreamble}>
              <StaggeredDelayedAnimation delay={1.3} stagger={0.025}>
                <AnimatedWords initialY={3} text={preamble} />
              </StaggeredDelayedAnimation>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

const ContactScene = (props) => {
  const { regularTitle, boldTitle, link } = props;
  const { t } = useTranslation();

  const linkLabel = link?.text || t('Generic.contactUs');

  const [programmticHoverActive, setProgrammaticHoverActive] = useState(false);
  const buttonDisplayDelay = 2;

  useEffect(() => {
    const delayBeforeHoverActive = buttonDisplayDelay * 1.4 * 1000;
    const hoverActiveDuration = 1 * 1000;

    setTimeout(() => {
      setProgrammaticHoverActive(true);
      setTimeout(() => {
        setProgrammaticHoverActive(false);
      }, hoverActiveDuration);
    }, delayBeforeHoverActive);
  }, []);

  return (
    <motion.div
      className={s.ContactSceneContainer}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          duration: 2,
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          duration: 0.2,
        },
      }}>
      <h2 className={s.ContactHeading}>
        <StaggeredDelayedAnimation stagger={0.225}>
          <span className={s.ContactHeadingRegular}>
            <AnimatedWords text={regularTitle} />
          </span>
          {boldTitle && (
            <span className={s.ContactHeadingBold}>
              <AnimatedWords text={boldTitle} />
            </span>
          )}
        </StaggeredDelayedAnimation>
      </h2>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.5,
            delay: buttonDisplayDelay,
          },
        }}>
        <ButtonSecondary
          borderColor="white"
          modifiers={[
            'InlineFlex',
            'IconHoverDown',
            programmticHoverActive ? 'ProgrammaticHover' : '',
          ]}
          icon="icon-arrow-right-white"
          asLink={true}
          onClick={() => {
            trackHeroCarouselCtaClick(linkLabel);
          }}
          href={link?.href}>
          {linkLabel}
        </ButtonSecondary>
      </motion.div>
    </motion.div>
  );
};

const sceneComponents = {
  [SCENE_TYPE_INTRO]: IntroScene,
  [SCENE_TYPE_REGULAR]: BaseScene,
  [SCENE_TYPE_CONTACT]: ContactScene,
};

const VideoTextCarouselHero = (props) => {
  const { scenes } = props;
  const introScene = scenes.find((scene) => scene.type === SCENE_TYPE_INTRO);

  const [inViewRef, isVisible] = useIntersectionObserver(
    0.3,
    undefined,
    false,
    true
  );

  const { t } = useTranslation();

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [nextSceneTimeoutId, setNextSceneTimeoutId] = useState(null);

  const [hasLowerControlsOpacity, setHasLowerControlsOpacity] = useState(false);

  const [slideWasManuallySelected, setSlideWasManuallySelected] =
    useState(false);

  const [videoNode, setVideoNode] = useState(null);
  const videoRefFn = useCallback((_videoNode) => {
    setVideoNode(_videoNode);
  }, []);

  const [currentVideoSrcs, setCurrentVideoSrcs] = useState(null);
  const prevCurrentVideoSrcs = usePreviousValue(currentVideoSrcs);
  const largeVideoMq = useMediaQuery('(min-width: 542px)');
  const browserZoomPercentage = useContext(ZoomLevelContext);

  useEffect(() => {
    setCurrentVideoSrcs(largeVideoMq ? videoSrcsDesktop : videoSrcsResp);
  }, [largeVideoMq]);

  // init video

  useEffect(() => {
    if (!videoNode) {
      return;
    }

    const activateVideo = () => {
      videoNode.play();
    };

    // ios
    document.addEventListener('touchstart', activateVideo);
    return () => {
      document.removeEventListener('touchstart', activateVideo);
    };
  }, [videoNode]);

  // handle video loaded event

  const videoHasLoaded = () => {
    setIsVideoPlaying(true);

    videoNode.play();
    videoNode.removeEventListener('canplaythrough', videoHasLoaded);
  };

  useEffect(() => {
    if (!videoNode) {
      return;
    }

    // we need to trigger load() manually if the video srcs have changed
    // (i.e. due to resized browser window)
    if (prevCurrentVideoSrcs !== currentVideoSrcs) {
      videoNode.load();
    } else {
      // if the video is in the cache of the browser,
      // the 'canplaythrough' event might have been triggered
      // before we registered the event handler.
      if (videoNode.readyState > 3) {
        videoHasLoaded();
        return;
      }
    }

    videoNode.addEventListener('canplaythrough', videoHasLoaded);
    return () => {
      videoNode.removeEventListener('canplaythrough', videoHasLoaded);
    };
  }, [videoNode, currentVideoSrcs]);

  // handle controls opacity fade after video start

  useDelayedEffect(
    CONTROLS_OPACITY_DELAY,
    () => {
      setHasLowerControlsOpacity(isVideoPlaying);
    },
    [isVideoPlaying]
  );

  // handle scene progression

  const [currentSceneIndex, setCurrentSceneIndex] = useState(0);
  useEffect(() => {
    const currentScene = scenes[currentSceneIndex];
    const currentSceneDuration = currentScene?.duration;

    if (!isPlaying) {
      return;
    }

    if (currentSceneIndex === scenes.length - 1) {
      clearTimeout(nextSceneTimeoutId);
      setTimeout(() => {
        setIsPlaying(false);
      }, currentSceneDuration * 1000);
    } else {
      const id = setTimeout(() => {
        setCurrentSceneIndex((i) => (i + 1) % scenes.length);
      }, currentSceneDuration * 1000);

      setNextSceneTimeoutId(id);
    }
  }, [currentSceneIndex, isPlaying]);

  // track slide impressions

  useEffect(() => {
    if (isVisible) {
      if (slideWasManuallySelected) {
        setSlideWasManuallySelected(false);
        return;
      }

      trackHeroCarouselSlideImpression(currentSceneIndex + 1);
    }
  }, [currentSceneIndex]);

  // event handlers

  const handleToggleScenesCarousel = () => {
    clearTimeout(nextSceneTimeoutId);
    if (currentSceneIndex === scenes.length - 1) {
      setCurrentSceneIndex(0);
    }

    if (isPlaying) {
      videoNode.pause();
    } else {
      videoNode.play();
    }

    setIsPlaying(!isPlaying);
  };

  const handleRestart = () => {
    clearTimeout(nextSceneTimeoutId);
    setCurrentSceneIndex(0);
    setIsPlaying(true);
  };

  const handleGoToScene = (idx) => {
    clearTimeout(nextSceneTimeoutId);
    setSlideWasManuallySelected(true);
    trackHeroCarouselSlideSelect(idx + 1);
    setCurrentSceneIndex(idx);

    if (!isPlaying && currentSceneIndex === scenes.length - 1) {
      setIsPlaying(true);
    }
  };

  // current scene

  const currentScene = scenes[currentSceneIndex];
  const SceneComponent = sceneComponents[currentScene?.type];

  // fade overlay

  const { scrollY } = useScroll();
  const fadeYEased = useTransform(scrollY, (v) => {
    const windowInnerHeight =
      typeof window !== 'undefined' ? window.innerHeight : 2000;
    const scrollYPx = scrollY.get();

    const fadeY = mapRange(scrollYPx, 0, windowInnerHeight, 0, 1);
    const eased =
      -1 * easeOutCubic(Math.min(fadeY, 1)) * windowInnerHeight * 0.5;

    return eased;
  });

  // classes

  const rootCls = classNames(s.Root, {
    [s.LowerControlsOpacity]: hasLowerControlsOpacity,
    [s.ZoomLevel200]: browserZoomPercentage >= 190,
    [s.ZoomLevel300]: browserZoomPercentage >= 290,
    [s.ZoomLevel300]: browserZoomPercentage >= 290,
    [s.ZoomLevel400]: browserZoomPercentage >= 390,
  });

  return (
    <div
      className={rootCls}
      aria-labelledby="hero-carousel-sr-heading"
      ref={inViewRef}>
      <div className={s.Wrapper}>
        <Div100vh className={s.Div100}>
          <motion.div className={s.FadeOverlay} style={{ y: fadeYEased }} />

          <h2 id="hero-carousel-sr-heading" className="sr-only">
            {introScene?.regularTitle} {introScene?.boldTitle}
          </h2>
          <div aria-live="polite" aria-atomic="true" className="sr-only">
            {t('Why.slide', { idx: currentSceneIndex + 1 })} of {scenes.length}
          </div>

          <div className={s.Container}>
            <video
              data-matomo-ignore
              className={s.Video}
              ref={videoRefFn}
              playsInline={true}
              autoPlay={true}
              loop={true}
              preload="auto"
              muted={true}>
              {currentVideoSrcs &&
                currentVideoSrcs.map((s, i) => (
                  <source key={i} src={s.src} type={s.type} />
                ))}
              <track kind="subtitles" src="/video/no-audio.vtt" srclang="en" />
            </video>

            <div className={s.SceneContainer}>
              <AnimatePresence>
                {currentScene && (
                  <SceneComponent key={currentSceneIndex} {...currentScene} />
                )}
              </AnimatePresence>
            </div>

            <div className={s.ProgressContainer}>
              <div className={s.RespButtonWrapper}>
                <div className={s.ButtonContainer}>
                  <button
                    className={s.PlayButton}
                    onClick={handleToggleScenesCarousel}>
                    <span className="sr-only">
                      {isPlaying ? t('Generic.pause') : t('Generic.play')}
                    </span>
                    <Image
                      width={20}
                      height={20}
                      src={`/img/${
                        isPlaying ? 'icon-pause-white' : 'icon-play-white'
                      }.svg`}
                      alt={isPlaying ? t('Generic.pause') : t('Generic.play')}
                      className={s.PlayIcon}
                    />
                  </button>
                </div>
                <AnimatePresence>
                  {currentSceneIndex === scenes.length - 1 && (
                    <motion.div
                      className={s.ButtonContainer}
                      initial={{
                        opacity: 0,
                        scale: 0,
                      }}
                      animate={{
                        opacity: 1,
                        scale: 1,
                        transition: {
                          delay: 2.3,
                        },
                      }}
                      exit={{
                        scale: 0,
                        opacity: 0,
                      }}>
                      <span className={s.ButtonTextResp}>
                        {t('Generic.restart')}
                      </span>
                      <button className={s.PlayButton} onClick={handleRestart}>
                        <span className="sr-only">{t('Generic.restart')}</span>
                        <Image
                          width={20}
                          height={20}
                          src={`/img/icon-restart-white.svg`}
                          alt="RestartButton"
                          className={s.PlayIcon}
                        />
                      </button>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              <div className={`${s.ButtonContainer} ${s.Desktop}`}>
                <button
                  className={s.PlayButton}
                  onClick={handleToggleScenesCarousel}>
                  <span className="sr-only">
                    {isPlaying ? t('Generic.pause') : t('Generic.play')}
                  </span>
                  <Image
                    width={20}
                    height={20}
                    src={`/img/${
                      isPlaying ? 'icon-pause-white' : 'icon-play-white'
                    }.svg`}
                    alt={isPlaying ? t('Generic.pause') : t('Generic.play')}
                    className={s.PlayIcon}
                  />
                </button>
              </div>
              <div className={s.Progress}>
                {scenes.map((scene, i) => {
                  const barStyle = {
                    width: `${100 / (scenes.length - 1)}%`,
                  };

                  const innerBarCls = classNames(s.ProgressLineInner, {
                    [s.Playing]: i === currentSceneIndex && isPlaying,
                    [s.Paused]: i === currentSceneIndex && !isPlaying,
                    [s.Played]: i < currentSceneIndex,
                  });

                  const innerBarStyle =
                    currentSceneIndex === i && isPlaying
                      ? {
                          animationDuration: `${scene?.duration}s`,
                          animationPlayState: 'running',
                          animationIterationCount:
                            scene.type === SCENE_TYPE_CONTACT
                              ? '1'
                              : 'infinite',
                        }
                      : null;

                  return (
                    <div key={i} className={s.ProgressLine} style={barStyle}>
                      {i <= currentSceneIndex && (
                        <div className={innerBarCls} style={innerBarStyle} />
                      )}
                      <button
                        className={s.ProgressLineButton}
                        onClick={() => handleGoToScene(i)}>
                        <span className="sr-only">
                          {t('Why.slide', { idx: i })}
                        </span>
                      </button>
                    </div>
                  );
                })}
              </div>

              <div className={`${s.ButtonContainer} ${s.Desktop}`}>
                <AnimatePresence>
                  {currentSceneIndex === scenes.length - 1 && (
                    <motion.button
                      className={s.PlayButton}
                      onClick={handleRestart}
                      initial={{
                        opacity: 0,
                        scale: 0,
                      }}
                      animate={{
                        opacity: 1,
                        scale: 1,
                        transition: {
                          delay: 2.3,
                        },
                      }}
                      exit={{
                        scale: 0,
                        opacity: 0,
                      }}>
                      <span className="sr-only">{t('Generic.restart')}</span>
                      <Image
                        width={20}
                        height={20}
                        src={`/img/icon-restart-white.svg`}
                        alt=""
                        className={s.PlayIcon}
                      />
                    </motion.button>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </Div100vh>
      </div>
    </div>
  );
};

VideoTextCarouselHero.propTypes = {
  scenes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf([
        SCENE_TYPE_INTRO,
        SCENE_TYPE_REGULAR,
        SCENE_TYPE_CONTACT,
      ]).isRequired,
      duration: PropTypes.number,
      regularTitle: PropTypes.string.isRequired,
      boldTitle: PropTypes.string.isRequired,
      preamble: PropTypes.string,
      link: PropTypes.shape({
        href: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }),
    })
  ),
};

VideoTextCarouselHero.defaultProps = {
  scenes: [],
};

export default VideoTextCarouselHero;

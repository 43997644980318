import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const StaggeredDelayedAnimation = (props) => {
  const { children, delay = 0, stagger = 0.15 } = props;

  const animProps = {
    hidden: {},
    visible: {
      transition: {
        delayChildren: delay,
        staggerChildren: stagger,
      },
    },
  };

  return (
    <motion.span variants={animProps} initial="hidden" animate="visible">
      {children}
    </motion.span>
  );
};

StaggeredDelayedAnimation.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
  stagger: PropTypes.number,
};

StaggeredDelayedAnimation.defaultProps = {
  delay: 0.3,
  stagger: 0.25,
};

const AnimatedWords = (props) => {
  const { text, duration, initialY } = props;

  const words = text.split(' ').filter((word) => word !== ' ');

  const wordAnimProps = {
    hidden: {
      opacity: 0,
      y: initialY,
      skew: 15,
    },
    visible: {
      opacity: 1,
      y: 0,
      skew: 0,
      transition: {
        duration,
        opacity: {
          duration: duration * 1.5,
        },
        y: {
          duration: duration * 0.8,
        },
        skew: {
          duration: duration * 1,
        },
      },
    },
  };

  return (
    <>
      {words.map((word, i) => {
        return (
          <span key={i}>
            <motion.span
              style={{
                display: 'inline-block',
                transformOrigin: '50% 50%',
              }}
              key={i}
              variants={wordAnimProps}>
              {word}
            </motion.span>
            <span>{i < words.length - 1 ? ' ' : ''}</span>
          </span>
        );
      })}
    </>
  );
};

AnimatedWords.propTypes = {
  duration: PropTypes.number,
  initialY: PropTypes.number,
  text: PropTypes.string.isRequired,
};

AnimatedWords.defaultProps = {
  duration: 0.7,
  initialY: 16,
  text: '',
};

const AnimatedSentences = (props) => {
  const { text = '', duration } = props;

  const splittedSentences = text.match(/[^\.!\?]+[\.!\?]+/g);
  const sentences = splittedSentences ? splittedSentences : [text];

  const sentenceAnimProps = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration,
      },
    },
  };

  return (
    <>
      {sentences?.map((sentence, i) => {
        return (
          <span key={i}>
            <motion.span
              style={{
                display: 'inline',
              }}
              key={i}
              variants={sentenceAnimProps}>
              {sentence}
              {i < sentences.length - 1 ? '' : ''}
            </motion.span>
          </span>
        );
      })}
    </>
  );
};

AnimatedSentences.propTypes = {
  duration: PropTypes.number,
  text: PropTypes.string.isRequired,
};

AnimatedSentences.defaultProps = {
  duration: 0.8,
};

export default StaggeredDelayedAnimation;
export { StaggeredDelayedAnimation, AnimatedSentences, AnimatedWords };

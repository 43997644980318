import React, { useState, useEffect } from 'react';

import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
import s from './WhatWeOfferModule.module.scss';
import classNames from 'classnames';
import Link from 'next/link';
import Image from 'next/image';
import useWindowWidthTitles from '../../hooks/useWindowWidthTitles';

const WhatWeOfferModule = ({
  title = {},
  preamble,
  pages,
  image,
  modifier,
  isDesktop,
}) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(undefined);
  const titleClasses = classNames(s.Title, 'grid', { [s.Visible]: isVisible });
  const titleMobileClasses = classNames(s.TitleMobile, {
    [s.Visible]: isVisible,
  });

  let HeadingTag = 'h1';
  if (modifier === 'HomePage') {
    HeadingTag = 'h2';
  }

  const contentClasses = classNames(s.Content, 'grid', {
    [s.NoContent]: !preamble,
    [s.Visible]: isVisible,
  });

  const imgStyle = { objectFit: 'cover' };

  if (!!image?.focal && !!image.focal.x && !!image.focal.y) {
    imgStyle.objectPosition = `${image.focal.x} ${image.focal.y}`;
  }

  const width = useWindowWidthTitles(isDesktop);

  const isMobile = width < 768;

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div
      className={classNames(s.Root, { [s.HomePage]: modifier === 'HomePage' })}>
      {isMobile ? (
        <HeadingTag className={titleMobileClasses}>
          <span className={titleClasses}>
            <span className={s.TitleContainer}>
              <span className={s.TitleTop}>{title.titleTop}</span>{' '}
              <span className={s.TitleBottom}>{title.titleBottom}</span>
            </span>
          </span>
        </HeadingTag>
      ) : (
        <HeadingTag className={s.TitleDesktop}>
          <span className={titleClasses}>
            <span className={s.TitleTop}>{title.titleTop}</span>
            <span className={s.TitleBottom}>{title.titleBottom}</span>
          </span>
        </HeadingTag>
      )}

      <div className={contentClasses}>
        <p className={s.Text}>{preamble}</p>
      </div>

      <div className={`${s.PageSwitcher} grid`}>
        {modifier === 'HomePage' && (
          <>
            <span className={s.BlobLeft} />
            <span className={s.BlobRight} />
          </>
        )}
        <div className={`${s.Links}`}>
          <h2 className={s.Tagline}>{t('WhatWeOfferCategoryPage.tagline')}</h2>
          {pages?.items?.map((item, i) => (
            <Link
              onMouseEnter={() => setCurrentImage(i)}
              onMouseLeave={() => setCurrentImage(undefined)}
              key={i}
              href={item.href}
              className={s.Link}>
              {item.title}
            </Link>
          ))}
        </div>
        <div className={s.Image}>
          <Image
            className={classNames(s.Img, {
              [s.Visible]: currentImage === undefined,
            })}
            src={image?.url}
            alt={image?.alt}
            title={image?.title}
            fill
            style={imgStyle}
          />
          {pages?.items?.map((item, i) => {
            const imageStyle = { objectFit: 'cover' };

            const imgClass = classNames(s.Img, {
              [s.Visible]: i === currentImage,
            });

            if (
              !!item?.image?.focal &&
              !!item.image.focal.x &&
              !!item.image.focal.y
            ) {
              imageStyle.objectPosition = `${item.image.focal.x} ${item.image.focal.y}`;
            }

            return (
              <Image
                key={i}
                className={imgClass}
                src={item.image?.url}
                alt={item.image?.alt}
                title={item.image?.title}
                fill
                style={imageStyle}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

WhatWeOfferModule.propTypes = {};

WhatWeOfferModule.defaultProps = {};

export default WhatWeOfferModule;

import React from 'react';

import dynamic from 'next/dynamic';
import { snakeToPascal } from '../../utils/caseconverters';
// import i18n from '../../i18n';
// import PropTypes from 'prop-types';
import s from './StreamField.module.scss';

const CardGlassLink = dynamic(() => import('../CardGlassLink'));
const Testemonial = dynamic(() => import('../Testemonial'));
const RichText = dynamic(() => import('../RichText'));
const ImageRichText = dynamic(() => import('../ImageRichText'));
const CardLinkRichText = dynamic(() => import('../CardLinkRichText'));
const FactsRichText = dynamic(() => import('../FactsRichText'));
const BlockQuoteRichText = dynamic(() => import('../BlockQuoteRichText'));
const VerticalScroller = dynamic(() => import('../VerticalScroller'));
const MediaBankModule = dynamic(() => import('../MediaBankModule'));
const NewsList = dynamic(() => import('../NewsList'));
const Stories = dynamic(() => import('../Stories'));
const BlockQuoteCta = dynamic(() => import('../BlockQuoteCta'));
const FiftyFiftyImages = dynamic(() => import('../FiftyFiftyImages'));
const AccordionList = dynamic(() => import('../AccordionList'));
const VideoPlayer = dynamic(() => import('../VideoPlayer'));
const ReportColumnChart = dynamic(() => import('../ReportColumnChart'));
const ReportPieChart = dynamic(() => import('../ReportPieChart'));
const ReportLineChart = dynamic(() => import('../ReportLineChart'));

const StreamField = ({ blocks = [], from }) => {
  if (!blocks.length) {
    return null;
  }
  const components = {
    CardGlassLink,
    TestimonialBlock: Testemonial,
    RichText,
    ImageRichText,
    CardLinkRichText,
    FactsRichText,
    BlockQuoteRichText,
    VerticalScroller,
    MediaBank: MediaBankModule,
    NewsList,
    Stories,
    BlockQuoteCta,
    FiftyFiftyImages,
    AccordionList,
    Video: VideoPlayer,
    ReportColumnChart,
    ReportPieChart,
    ReportLineChart,
  };

  const dynamicComponents = blocks.map((item, index) => {
    const componentName = snakeToPascal(item.type);
    const Component = components[componentName];
    return <Component key={index} {...item.value} fromPage={from} />;
  });

  return <>{dynamicComponents}</>;
};

StreamField.propTypes = {};

StreamField.defaultProps = {};

export default StreamField;
